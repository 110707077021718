import i18n, { Callback } from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

import { currentEnvironment } from '../../environment/environment';

const customLanguageDetector = (): string => {
  switch (currentEnvironment()) {
    case 'cz':
      return 'cs';
    case 'hu':
      return 'hu';
    case 'sk':
      return 'sk';
    default:
      throw new Error('CustomLanguageDetector invalid enviroment');
  }
};

export const i18nService = (callback: Callback): void => {
  // don't want to use this?
  // have a look at the Quick start guide
  // for passing in lng and translations on init

  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
      {
        lng: customLanguageDetector(),
        fallbackLng: [customLanguageDetector()],
        /* fallbackLng: ['cs'], */
        react: {
          useSuspense: false,
        },
        load: 'languageOnly',
        ns: [
          'common',
          'menu',
          'homePage',
          'contacts',
          'signIn',
          'contracts',
          'announcement',
          'chat',
          'selfcare',
          'api',
          'cookies',
          'supportedBrowsers',
          'unsupportedBrowser',
          'xcally',
          'app',
          'components',
          'whistleblowing',
        ],
        debug: process.env.NODE_ENV !== 'production',
        interpolation: {
          escapeValue: true, // not needed for react as it escapes by default,
          format: (value, format, lng) => {
            if (format === 'lowercase') return value.toLowerCase();

            return value;
          },
        },
        backend: {
          loadPath: '/locales/v1.0.1/{{lng}}/{{ns}}.json',
        },
      },
      callback
    );
};

export default i18n;
